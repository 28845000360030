import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"]

    connect() {
        console.log("Record Import controller connected")
        this.updateColumnClasses()
    }

    updateMapping(event) {
        event.preventDefault()
        const form = event.currentTarget
        const formData = new FormData(form)

        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(data => {
                if (data.notice) {
                    console.log(data.notice)
                }
                this.updateColumnClasses()
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    updateColumnClasses() {
        const mappingSelects = this.element.querySelectorAll('.mapping-select')
        mappingSelects.forEach(select => {
            const column = select.dataset.column
            const mapped = select.value !== ''
            const cells = document.querySelectorAll(`#preview_table th:nth-child(${parseInt(column) + 1}), #preview_table td:nth-child(${parseInt(column) + 1})`)

            cells.forEach(cell => {
                cell.classList.toggle('mapped', mapped)
                cell.classList.toggle('unmapped', !mapped)
            })
        })
    }
}
